import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useId,
  useState,
} from 'react'

import { DropdownArrow } from '@/features/common/'
import { useElementHeight } from '@/hooks/useElementRect'
import { colors } from '@/theme/variables'

import { fieldStyles } from './styles/fieldStyles'

type Props = {
  data?: Queries.FormSelectFieldFragment | null
  onChange: (name: string, value: string) => void
  sectionName?: string | null
}

export const FormSelectField = ({
  data,
  sectionName,
  onChange,
}: Props): JSX.Element => {
  const name = `${sectionName ? kebabCase(sectionName) + '-' : ''}${data?.label ? kebabCase(data?.label) : ''}`

  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')

  const handleShrink = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }

  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const allOptions = data?.options?.flatMap(optionGroup => {
    const json = JSON.parse(
      (optionGroup?.json || '') as unknown as string
    )
    return json
  })

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (allOptions?.includes(e.target.value)) {
        setValue(e.target.value)
        onChange(name, e.target.value)
      }
    },
    [onChange, name, allOptions]
  )

  const uniqueId = useId()

  const [labelRef, setLabelRef] = useState<HTMLElement | null>(null)
  const labelHeight = useElementHeight(labelRef)

  const [activeOptionRef, setActiveOptionRef] =
    useState<HTMLElement | null>(null)
  const activeOptionHeight = useElementHeight(activeOptionRef)

  const styles = {
    select: css`
      appearance: none;
      color: transparent;
      min-height: max(
        3.333em,
        2em + ${labelHeight || 0}px,
        ${activeOptionHeight || 0}px
      );
      transition: min-height 150ms ease;

      /* hide text in webkit */
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: transparent !important;
      }
      option {
        color: var(--text-color);
        &:disabled {
          color: var(--text-color);
          opacity: 0.67;
        }
      }
    `,
    inputValue: css`
      position: absolute;
      display: block;
      pointer-events: none;
      line-height: 1.333;
    `,
    arrow: css`
      position: absolute;
      top: 50%;
      right: 1.5em;
      transform: translateY(-33%);
      pointer-events: none;
      @media (hover: hover) {
        div:hover > & {
          color: var(--textHighlight);
        }
      }
      div:focus-within > & {
        background-color: ${colors.red};
      }
    `,
  }

  return (
    <div css={() => fieldStyles.container(data?.width)}>
      <label
        htmlFor={name + uniqueId}
        css={[
          fieldStyles.label,
          shrink && fieldStyles.shrink,
          data?.required && fieldStyles.required,
        ]}
        ref={node => setLabelRef(node)}
      >
        {data?.label}
      </label>
      <DropdownArrow css={styles.arrow} />
      {value && (
        <div
          css={[fieldStyles.input, styles.inputValue]}
          aria-hidden
          ref={node => setActiveOptionRef(node)}
        >
          {value}
        </div>
      )}
      <div css={fieldStyles.inputBase}>
        <select
          css={[fieldStyles.input, styles.select]}
          name={name}
          id={name + uniqueId}
          required={data?.required || undefined}
          onChange={handleChange}
          onFocus={handleShrink}
          onBlur={handleShrink}
          defaultValue=""
        >
          <option
            value=""
            disabled
            aria-hidden
          >
            {data?.label}
          </option>
          {allOptions?.map(option => {
            return (
              <option
                value={option || ''}
                key={option}
              >
                {option}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export const FormSelectFieldFragment = graphql`
  fragment FormSelectField on DatoCmsFormSelectField {
    __typename
    id: originalId
    label
    options {
      ... on DatoCmsFormSelectOptionsList {
        json
      }
      ... on DatoCmsFormSelectStatesList {
        json
      }
    }
    required
    width
  }
`
